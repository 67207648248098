import React from 'react'
import styles from './styles/type1.module.scss'
import Image from 'next/image'

interface LogoListType1 {
  list: Array<{
    couponPath: string
    maxoff: string
    storeLogo: string
    storeName: string
    title: string
    updated_at: string
  }>
}

export default function Type1({ list }: LogoListType1) {
  return (
    <ul className={styles.type1}>
      {list.map((item, index) => (
        <li key={index} className={styles.logo_list_item}>
          <a>
            <div>
            <div className={styles.logo}>
              <img src={item.storeLogo} alt={item.storeName} />
              <p className={styles.maxoff}>{item.maxoff}</p>
            </div>
            <div className={styles.text}>
              <div className={styles.border_top}></div>
              <h3>{item.storeName}</h3>
              <p className="text-elli-3">{item.title}</p>
            </div>
            </div>
          </a>
        </li>
      ))}
    </ul>
  )
}
